import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/OoIJxj5I1J0">
    <SEO title="Diseases of Our Day - Jesus in Genesis" />
  </Layout>
)

export default SermonPost
